jQuery(function($) {
	// slider
	let slider = $('.slider');

	slider.slick({
		adaptiveHeight: false,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 3000,
		dots: false,
		draggable: false,
		fade: false,
		infinite: true,
		pauseOnHover: false,
		swipe: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					draggable: true,
					swipe: true,
				}
			}
		]
	});
});