const headerHeight	= 0;
const responsive		= 984;

function resize() {
	$('body > div, #navigation').removeAttr('style');

	const ulHeight = $('#navigation > ul').innerHeight() + headerHeight;
	const windowHeight = $(window).height();

	if(ulHeight < windowHeight) {
		$('body > div').css('height', windowHeight);
		$('#navigation').css('height', windowHeight - headerHeight);
	} else {
		$('body > div').css('height', ulHeight);
		$('#navigation').css('height', ulHeight - headerHeight);
	}
}

jQuery(function($) {
	$('.hamburger').on('click', function() {
		if($('body').hasClass('show-nav')) {
			$('body').removeClass('show-nav').addClass('hide-nav');
			$('body > div, #navigation').removeAttr('style');
			$('#navigation *').removeClass('current opened');

			setTimeout(function() {
				$('body').removeClass('hide-nav');
			}, 500);
		} else {
			$('body').removeClass('hide-nav').addClass('show-nav');
			resize();
		}
		return false;
	});

	$('#navigation ul > li > .link + span').on('click', function() {
		const $prev = $(this).prev();
		const $next = $(this).next();

		$(this).parent('li').find('ul').not($next).removeClass('opened');
		$(this).parent('li').siblings().find('ul').removeClass('opened');
		$(this).parent('li').find('a').removeClass('current');
		$(this).parent('li').siblings().find('a').removeClass('current');

		if($next.hasClass('opened')) {
			$next.removeClass('opened');
		} else {
			$prev.addClass('current');
			$next.addClass('opened');
		}

		setTimeout(function() {
			resize();
		}, 500);
	});

	$(window).resize(function() {
		if($(window).width() < responsive) {
			if($('body').hasClass('show-nav')) {
				resize();
			}
		} else {
			$('body').removeClass('show-nav');
			$('body > div, #navigation').removeAttr('style');
			$('#navigation *').removeClass('current opened');
		}
	});
});

jQuery(document).keyup(function(e) {
	if(e.keyCode == 27) {
		if($(window).width() < responsive) {
			if($('body').hasClass('show-nav')) {
				$('body').removeClass('show-nav').addClass('hide-nav');
				$('body > div, #navigation').removeAttr('style');

				setTimeout(function() {
					$('body').removeClass('hide-nav');
				}, 500);
			}
		}
	}
});